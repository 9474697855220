/* ###### Main-footer  ###### */

.main-footer {
  background-color: $white;
  padding: 15px;
  display: block;
  margin-top: auto;
  background-image: url(../../img/texture-footer.svg);
  background-size: cover;
  position: relative;
  border-top: .5px solid #d8dee5;

  &::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;left: 0;right: 0;bottom: 0;
    background-color: $white-8;
  }

  .container {
    font-size: 12px;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .container-fluid {
    font-size: 12px;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 992px) {
  .main-footer {
    display: block;
  }
}

.main-footer-app {
  .container,
  .container-fluid {
    border-top-width: 0;
  }
}

.main-footer-demo {
  background-color: #0c025b;
  color: $white-4;
  height: 60px;

  .container {
    border-top-width: 0;
  }

  a {
    color: $white-5;

    &:hover,
    &:focus {
      color: $white;
    }
  }
}
@media (max-width: 768px) {
  .main-content.side-content {
    min-height: calc(100vh - 48px);
  }
}

/* ###### Main-footer  ###### */

/* Importing node modules SCSS file. */
// @import '~bootstrap/scss/bootstrap';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import '~animate.css/animate.min.css';
@import "~simple-datatables/dist/style.css";
@import "~leaflet/dist/leaflet.css";
@import "~chartist/dist/scss/chartist.scss";

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import "~intl-tel-input/build/css/intlTelInput.css";
@import "~angular2-multiselect-dropdown/themes/default.theme.css";
@import '~dropzone/dist/min/dropzone.min.css';
@import '~ngx-toastr/toastr.css';
@import '~angular-archwizard/archwizard.css';
@import '~animate.css/animate.min.css';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '~@swimlane/ngx-datatable/themes/dark.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import "~angular-calendar/scss/angular-calendar.scss";
@import "~flatpickr/dist/flatpickr.css";
@import '~highlight.js/scss/solarized-dark.scss';

//custom scss
@import "scss/style.scss";
// @import "css/skins.scss";
// @import "css/dark-style.scss";
// @import "css/colors/default.scss";
@import "assets/css/icon-list.css";
// @import "css/colors/color.scss";

@import "css/sidemenu/sidemenu.scss";

@import "../app/components/mk-table/mk-table.scss";


.st-default,.st-new{background-color: #666};
.st-analisis{background-color: #ecb709};
.st-auth_pen_adm{background-color: #417dec};
.st-auth_pen_cli{background-color: #417dec};
.st-autorizado{background-color: #2d3dce};
.st-desarrollo{background-color: #e67c35};
.st-trabajando{background-color: #e67c35};
.st-lib_pen_adm{background-color: #35c063};
.st-lib_pen_cli{background-color: #35c063};
.st-liberado{background-color: #22a84f}; //22a84f
.st-cerrado{background-color: #35363f};
.st-rechazado{background-color: #ec5353};
.st-lib_rej_cli{background-color: #ec5353};

.badge{
    &.badge-st-default,&.badge-st-new{color: #666; background-color: #66666633};
    &.badge-st-analisis{color: #ecb709; background-color: #ecb70933};
    &.badge-st-auth_pen_adm{color: #417dec; background-color: #417dec33};
    &.badge-st-auth_pen_cli{color: #417dec; background-color: #417dec33};
    &.badge-st-autorizado{color: #2d3dce; background-color: #2d3dce33};
    &.badge-st-desarrollo{color: #e67c35; background-color: #e67c3533};
    &.badge-st-trabajando{color: #e67c35; background-color: #e67c3533};
    &.badge-st-lib_pen_adm{color: #35c063; background-color: #35c06333};
    &.badge-st-lib_pen_cli{color: #35c063; background-color: #35c06333};
    &.badge-st-liberado{color: #22a84f; background-color: #22a84f33};
    &.badge-st-cerrado{color: #35363f; background-color: #35363f33};
    &.badge-st-rechazado{color: #ec5353; background-color: #ec535333};
    &.badge-st-lib_rej_cli{color: #ec5353; background-color: #ec535333};
}

// COLORES DENOMINACIONES

.c-usd-c--01{color: #f39542 !important;}
.c-usd-c--05{color: #a5a2a2 !important;}
.c-usd-c--1{color: #9b9999 !important;}
.c-usd-c--25{color: #938d8d !important;}
.c-usd-c-1{color: #ecdb58 !important;}
.c-usd-b-1{color: #98b769 !important;}
.c-usd-b-2{color: #98b769 !important;}
.c-usd-b-5{color: #c7d065 !important;}
.c-usd-b-10{color: #d1ce6c !important;}
.c-usd-b-20{color: #84b865 !important;}
.c-usd-b-50{color: #c7a97a !important;}
.c-usd-b-100{color: #cedda4 !important;}

.c-mxn-c--5{color: #d8ad41 !important;}
.c-mxn-c-1{color: #a5a2a2 !important;}
.c-mxn-c-2{color: #9b9999 !important;}
.c-mxn-c-5{color: #938d8d !important;}
.c-mxn-c-10{color: #ebc034 !important;}
.c-mxn-c-20{color: #e5d435 !important;}
.c-mxn-b-20{color: #ffa77b !important;}
.c-mxn-b-50{color: #eb71cb !important;}
.c-mxn-b-100{color: #ef686f !important;}
.c-mxn-b-200{color: #8cd37b !important;}
.c-mxn-b-500{color: #61a4de !important;}
.c-mxn-b-1000{color: #606870 !important;}

.pac-container {
    z-index: 2000;
    display: inline-block;
}
.input-group.date .md-drppicker{
   
    top: 55px !important;
    
    &.double{left: unset !important; right: 0 !important;}
}
.md-drppicker{ display: flex;}

.btn i.typcn.typcn-eye {
    line-height: 12px;
    font-size: 1.1em;
}

app-sidemenu{
    .ps__rail-x{display: none !important;}
    .ps.ps--active-y:hover .ps__rail-y{right: 0 !important;}
}



.main-content{
    --mkt-scroll: #17203d54;
    background-image: url(../assets/img/bg.png);
    background-size: cover;
    
    &::before{
        // content: '';∂∂
        display: block;
        width: 100%;
        height: 90vh;
        background-image: url(../assets/img/pattern.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top right;
        position: absolute;
        right: 0;bottom: 0;
        opacity: 0.2;
        pointer-events: none;
        mix-blend-mode: darken;
    }
}

tr.mat-header-row{height: fit-content !important;}
th.mat-header-cell, td.mat-cell, td.mat-footer-cell{
    &:not(.p-0):first-of-type {padding-left: 6px !important;}
    &:not(.p-0):last-of-type{ padding-right: 6px !important;}
}
.mat-sort-header-arrow{top: -2px; color: var(--primary);}
.mat-row{height: fit-content !important;}

.mat-paginator-range-actions button{
    border-radius: 8px;
    border: 1px solid #0002;

    &:not(:last-child){margin-right: 8px;}

    .mat-button-wrapper{
        position: relative;top: -2px;
    }
    

    &:not(.mat-button-disabled){color: var(--primary);}
}

.ng-select.ng-select-disabled>.ng-select-container{background-color: unset; opacity: 0.4;}
ng-select.muted>.ng-select-container{background-color: unset; opacity: 0.7;}

mkt-dropdown{
    .md-drppicker{
        top: 0 !important;
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        margin: 0 !important;

        .calendar-table{
            background-color: transparent;
        }
    }
}


aw-wizard-navigation-bar li.editing .step-indicator,
aw-wizard-navigation-bar li.completed .step-indicator,
aw-wizard-navigation-bar li.current .step-indicator{background-color: var(--info) !important;}

aw-wizard-navigation-bar li.done{
    &:not(.current){
        .step-indicator{background-color: #E6E6E6 !important;}
    }
}


.selectgroup{
    .selectgroup-button{
        border-radius: 11px;
        color: inherit;

        b, span{
            display: block;
            width: 100%;
            text-align: left;
        }

        b{font-size: 1.2em;}
    }
}


.__filters{
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 86px;
    right: 23px;
    z-index: 5;

    box-shadow: 0 2px 10px 6px rgb(27 27 54 / 22%);
  
    .input-group{
      width: 240px;
      margin-bottom: 0 !important;
  
      &.search{width: 250px;}
      &.date{width: 230px;}
  
      &:not(:last-child){input{border-radius: 0;}}
      &:not(:first-child){.input-group-text{border-radius: 0; border-left: 0;}}
    }
  }
  

    .main-sidebar-body li.nav-item.active li.nav-sub-item{pointer-events: all !important;}

.mat-table,.mat-paginator{background: none;}

.__texture{
    background-image: url(./img/trianglePattern.png);
    background-size: cover;
}

._disabled{
    filter: saturate(0) opacity(.6);
    pointer-events: none;
}


._pill{
    width: fit-content;
    margin: auto;
    display: flex;
    padding: 3px 10px;
    border-radius: 10px;
    // background-color: hsla(224, 33%, 23%, 0.102);

    span{
        height: 21px;
        padding: 2px;
        font-size: .9em;
        font-weight: 700;
        white-space: nowrap;

        &:first-child{width: 22px;}
    }
    
}


.ng-select .ng-select-container{background: unset; z-index: unset !important;}
.ng-select{height: fit-content !important;}
.ng-dropdown-panel{z-index: 10 !important;}
.css_mkTable {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;

    --mkt-color: #00818a;

    ._title,
    ._body {
        border-bottom: 1px solid #eaeaea;
    }

    ._title {
        .mkt_col{
            font-weight: 500;
            color: #666;
            text-transform: uppercase;
            line-height: 38px;
            padding: 0 0;
            font-size: .85em;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    ._body{
        overflow-x: auto;
        overflow-y: visible;
        height: fit-content;
        position: relative;
    }


    .form-control {
        border: 0;
        border-radius: 0;
        font-weight: 500;
        width: calc(100% - 2px);
        margin: 0 1px;
        border-bottom-right-radius: 10px;
        position: relative;
        top: 1px;
        background-color: transparent;

        &::placeholder{opacity: 1; color: var(--mkt-color);}

        &:focus{outline: 1px solid var(--mkt-color); &::placeholder{opacity: .6;}}
    }
}

.mkt_row {
    width: fit-content;
    min-width: 100%;
    // cursor: grab;
    display: flex;
    flex-direction: column;
    line-height: 20px;
    position: relative;

    .line{
        width: fit-content;
        min-width: 100%;
        align-content: stretch;
        display: flex;

        &:not(._subitems)._border{
            // pointer-events: none;
            // position: absolute;
            // height: 100%;
            .mkt_col{
                // border-right: 1px solid #eaeaea;
                // height: 100% !important;
                &:not(:last-child) {
                    border-right: 1px solid #eaeaea;
                }
            }
        }

        .fa-lock{opacity: 0.7; font-size: .7em; position: relative; top: -1px; margin-right: 4px;}

        &._hover:hover{
            background-color: #428cff11 !important;
            
            .controls .del{background-color: var(--danger); color: white;}
        }
    }

    &:not(._subitems):nth-child(even) {
        background-color: #F8F8FB;
    }

    &:nth-child(even) .controls{background-color: #f9f9f9dd;}
    &:nth-child(odd) .controls{background-color: #f0eff4dd;}

    ._title,
    ._body {
        width: 100%;
    }
}

.mkt_col {
    width: 100%;
    height: 40px;
    min-width: var(--width);
    max-width: var(--width);
    // padding: 6px 8px;
    overflow: visible;
    position: relative;

    &.has-change{
        &::after{
            content: '';
            display: block;
            width: 4px;height: 4px;
            background-color: var(--mkt-color);
            top: 0;right: 0;
            position: absolute;
            animation-name: _fade;
            animation-duration: 2s;
        }

        @keyframes _fade {
            0%{opacity: 1;}
            100%{opacity: 0;}
        }
    }

    // COLUMN TYPES

    &.col-text {
        --width: 320px;
        // --width: 100%;
        // min-width: 280px !important;
        text-align: left;
    }
    &.col-number {
        --width: 120px;
    }
    &.col-user {
        --width: 80px;
    }
    &.col-check {
        --width: 80px;
    }
    &.col-status, &.col-status-change {
        --width: 120px;
    }
    &.col-select {
        --width: 160px;
    }
    &.col-rating {
        --width: 130px;
    }
    &.col-log {
        --width: 180px;
    }
    &.col-date {
        --width: 140px;
    }
    &.col-link {
        --width: 150px;
    }
    &.col-files {
        --width: 120px;
    }
    &.col-tag {
        --width: 120px;
    }

    &:not(.col-text,.col-select){text-align: center;}

}
._subitems{
    padding: 20px 0 30px;
    z-index: 2;

    &::before, .card .mkt_row::before{
        content: '';
        display: block;
        position: absolute;
        background-color: var(--mkt-color);
        opacity: 0.3;
        z-index: 1;
    }
    &::before{
        width: 2px;
        height: calc(100% - 40px);
        top: -8px;
        left: 57.5px;
    }
    .card .mkt_row::before{
        height: 2px;
        width: 20px;
        top: 50%;
        left: -20px;
    }

    .controls{
        background-color: transparent !important;
        position: relative;
        &::before, &::after{
            content: '';
            display: block;
            position: absolute;
            background-color: #7390CE;
        }
        
        &.float{
            position: absolute;
            top: 0;
            left: -80px;
        }
    }
    .card{
        width: calc(100% - 10px); 
        margin: 0 10px 0 0;
        // background-color: #DFE9FC;
        background-color: #fff;

        .mkt_row{
            background-color: transparent !important;

            &:not(:last-child){border-bottom: 1px solid #0001;}
        }

        .mkt_col{
            &:last-child{
                min-width: calc(var(--width) - 10px);
                max-width: calc(var(--width) - 10px);
            }
        }

        .form-control{border-bottom-left-radius: 10px;}
    }

    &.no-data{
        .form-control{border-top-left-radius: 10px;border-top-right-radius: 10px;}
    }

    .__title .mkt_col{
        height: fit-content;
        font-size: .8em;
        opacity: 0.7;
        font-weight: 500;
        position: relative;
        top: -10px;
        padding: 0 0;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.mkt_col.controls{
    display: flex;
    justify-content: space-between;
    --width: 80px;
    position: sticky;
    left: 0;
    z-index: 2;
    padding: 0 8px;
    border-right: 1px solid #eaeaea;

    
    .dot{
        content: '';
        display: block;
        min-width: 14px;
        height: 14px;
        position: absolute;
        top: 2px;
        right: 2px;
        z-index: 10;
        background-color: var(--secondary);
        box-shadow: 0 0 6px var(--secondary);
        border-radius: 50%;
        font-size: 10px;
        color: white;
        font-weight: 600;
        line-height: 14px;
    }
}

.mkt_col.controls, ._delete{
    button{
        width: 24px;height: 24px;
        border-radius: 10px;
        border: 0;
        margin: auto 0;
        color: white;
        text-align: center;
        line-height: 24px;
        padding: 0;
        z-index: 2;

        &:disabled{opacity: 0.4;}

        &.dropdown:not(.active){background-color: var(--dark);}
        &.dropdown.active{
            background-color: var(--mkt-color);
            box-shadow: 0 0 6px var(--mkt-color);

            i{transform: rotate(180deg);}
        }

        &.det{background-color: var(--mkt-color);}
        &.del{
            color: var(--danger);
            background-color: #c2c5cf66;
        }
    }
}

.mkt_row.gu-mirror {
    width: 800px !important;height: 40px !important;
    background-color: white;
    border: 1px solid #0002;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 10px #0005;
    z-index: 10;
    cursor: grabbing;

    ._subitems{display: none;}

    .mkt_col {
        width: 100%;
        padding: 6px 8px;

        &:not(:last-child) {border-right: 1px solid #eaeaea;}

        // &.col-text{--width: 300px}
    }
}


._hide_table{
    border-radius: 50%;
    width: 28px;
    height: 28px;
    padding-left: 0;padding-right: 0;
    text-align: center;
  }

._delete{
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    width: 80px;
    overflow: hidden;

    .wrap{
        height: 100%;width: 100%;
        position: relative;
        right: -80px;
        display: flex;
        background: linear-gradient(90deg, transparent, #e6e8ee);
        transition: .3s;

        button{
            margin: auto 10px auto auto;
    
            background-color: var(--danger);
            color: white;
        }
    }

  }

  mkt-row{position: relative; &:hover ._delete .wrap{right: 0;}}


.mkt_dropdown{
    box-sizing: content-box;
    height: fit-content;
    width: fit-content;

    &:not(.size){
        max-width: calc(var(--width) + 10px) !important;
        min-width: calc(var(--width) + 10px) !important;
    }
    
    .head{
        padding: 10px 0;
        border-bottom: 1px solid #0003;
        font-weight: 500;
        opacity: 0.7;
        text-align: center;
    }
    .content{
        padding: 10px 5px;

        img{min-width: 500px; max-height: calc(90vh); max-width: calc(100vw - 200px);}
        ._block{
            line-height: 1.2;
            min-height: 24px;
            height: fit-content;
            padding: 4px;
        }
    }


    ._browser{
        border-bottom: 1px solid #0002;
        padding: 6px;
        position: relative;

        input{
            width: 100%;
            margin: 0;
            font-weight: 400;
            padding-left: 24px;
            color: black;
            line-height: 1.2 !important;
        }

        .fa{
            position: absolute;
            color: #2b386227;
            top: 12px;
            left: 12px;
        }
    }
}